import _ from 'lodash';

function list (token, resolve, reject, complete) {
  console.log('geofences.list ...');
  
  this.$api.SSP.get('/geofences', {
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  })
  .then(response => {
    console.log('geofences.list => ', response);
    resolve(response.data);
  })
  .catch(error => {
    this.handleError(error);
    if (this.isValid(reject)) reject(error);
  })
  .finally(() => {
    // callback function
    if (this.isValid(complete)) complete();
  });
}

function customs (token, brand, cities, resolve, reject, complete) {
  console.log('customs.list ...');
  
  this.$api.SSP.get('brands/'+brand+'/custom?cities='+_.join(cities, ','), {
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  })
  .then(response => {
    console.log('customs.list => ', response);
    resolve(response.data);
  })
  .catch(error => {
    this.handleError(error);
    if (this.isValid(reject)) reject(error);
  })
  .finally(() => {
    // callback function
    if (this.isValid(complete)) complete();
  });
}

export {
  list,
  customs
}
