<template>
  <v-app>
    <top-bar 
      :brands="brands"
      :roles="user.roles"
      :user="user"
      :admin="admin"
      :ghost="ghost"
      :updated="updated"
      :loading="loading"
      @get-brand="loadBrand"
      @create-brand="addBrand"
      @manage-brand="manageBrand"
      @manage-people="managePeople"
      @manage-user="manageUser"
      @toggle-access="toggleAccess"
      @end-access="endAccess"
    />

    <page-view 
      :admin="admin"
      @get-brand="loadBrand"
    />

    <v-dialog
      v-model="views.brand.toggle"
      transition="slide-y-reverse-transition"
      content-class="brand-dialog"
      persistent
    >
      <brand-card 
        :id="views.brand.selected"
        :data="brandData"
        :agencies="agencies"
        :cities="cities"
        :admin="admin"
        :updated="views.brand.updated"
        :loading="views.brand.loading"
        :users="availableUsers"
        :user="user"
        :ref="`brand-view`"
        class=""
        @toggle="toggleBrand"
        @save="saveBrand"
      />
    </v-dialog>

    <v-dialog
      v-model="views.brand.people.toggle"
      transition="slide-y-reverse-transition"
      content-class="brand-dialog"
    >
      <v-card
        class="people-manager"
      >
        <v-progress-linear
          :active="views.brand.loading"
          indeterminate
          absolute
          top
          height="2"
        />
        <v-card-title class="text-subtitle-1 text-overline pr-12">
          Convidar Pessoas
        </v-card-title>
        <v-btn
          fab
          icon
          small
          absolute
          top
          right
          :disabled="loading"
          color="grey"
          class="mt-7 mr-n2"
          @click="togglePeople(false)"
        >
          <v-icon>
            {{ icons.close }}
          </v-icon>
        </v-btn>
        <v-card-text class="brand-content py-6 scrollable">
          <v-card
            outlined
            class="advertiser mb-2"
          >
            <v-select
              v-model="views.brand.people.advertiser"
              :items="brandAdvertisers"
              item-value="id"
              item-text="title"
              hide-details
              solo
              flat
              :disabled="views.brand.loading||!multipleAdvertisers"
              ref="advertiser"
              class="advertiser-field py-1 pl-1"
            >
              <template v-slot:item="data">
                <span 
                  class="text-overline"
                >
                  {{ data.item.title }}
                </span>
              </template>
            </v-select>
            <v-divider />
            <users-control
              :users="views.brand.people.users"
              :suggestions="availableUsers"
              :profiles="advertiserProfiles"
              :disabled="views.brand.loading"
              @update="updatePeople"
            />
          </v-card>
        </v-card-text>
        <v-expand-transition>
          <v-card-actions 
            class="pr-6 py-4"
          >
            <v-spacer />
            <v-btn
              depressed
              color="primary"
              :disabled="disablePeople"
              :loading="views.brand.loading"
              class="px-4"
              @click="savePeople"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-expand-transition>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="views.user.toggle"
      transition="slide-y-reverse-transition"
      content-class="profile-dialog"
      persistent
    >
      <v-slide-y-reverse-transition>
        <user-profile
          v-if="views.user.toggle"
          :data="user"
          :updated="views.user.updated"
          :loading="views.user.loading"
          ref="user-view"
          @toggle="toggleUser"
          @save="saveUser"
        />
      </v-slide-y-reverse-transition>
    </v-dialog>

    <v-dialog
      v-model="views.access.toggle"
      transition="slide-y-reverse-transition"
      content-class="access-dialog"
    >
      <v-card
        :loading="views.brand.loading"
        class="access-container"
      >
        <v-card-title class="text-subtitle-1 text-overline pr-12">
          Acessar como...
        </v-card-title>
        <v-btn
          fab
          icon
          small
          absolute
          top
          right
          :disabled="views.access.loading"
          color="grey"
          class="mt-7 mr-n2"
          @click="toggleAccess(false)"
        >
          <v-icon>
            {{ icons.close }}
          </v-icon>
        </v-btn>
        <v-divider />
        <v-card-text class="access-content py-8">
          <v-card
            outlined
            class="access-field mb-2"
          >
            <v-autocomplete
              v-model="views.access.selected"
              :loading="views.access.loading"
              :disabled="views.access.disabled||views.brand.loading"
              :label="views.access.label"
              :items="availableUsers"
              item-value="email"
              item-text="name"
              return-object
              hide-details
              hide-no-data
              solo
              flat
              class=""
            />
          </v-card>
        </v-card-text>
        <v-card-actions 
          class="pr-6 py-4"
        >
          <v-spacer />
          <v-btn
            depressed
            color="primary"
            :disabled="views.access.selected==null"
            :loading="views.access.loading"
            class="pr-3"
            @click="startAccess"
          >
            <v-icon left>
              {{ icons.ghost }}
            </v-icon>
            Acessar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay
      :value="updating"
      opacity=".8"
      class="text-center"
    >
      <v-progress-circular 
        indeterminate 
        size="64" 
        color="primary"
        z-index="10"
      />
      <v-subheader>
        Verificando updates...
      </v-subheader>
    </v-overlay>

    <toast />
  </v-app>
</template>

<script>
  import { 
    mdiClose,
    mdiCheck,
    mdiGhostOutline
  } from '@mdi/js';
  import services from '@/services'
  import { list as getBrands, get as getBrand, create as createBrand, update as updateBrand, agencies as getAgencies } from '@/api/brands'
  import { get as getUser, update as updateUser, ghost as getGhostAccess } from '@/api/users'
  import { list as getGeofences } from '@/api/geofences'
  import { sync } from 'vuex-pathify'
  const moment = require('moment');

  export default {
    name: 'LayoutHome',

    props: {
      updating: {
        type: Boolean,
        default: false,
      },
      admin: {
        type: Boolean,
        default: false,
      }
    },

    data: () => ({
      icons: {
        close: mdiClose,
        check: mdiCheck,
        ghost: mdiGhostOutline
      },
      views: {
        brand: {
          selected: null,
          toggle: false,
          title: '',
          people: {
            toggle: false,
            advertiser: null,
            users: []
          },
          loading: false,
          updated: false
        },
        user: {
          toggle: false,
          loading: false,
          updated: false,
        },
        access: {
          toggle: false,
          label: 'Selecionar o usuário...',
          loading: false,
          updated: false,
          selected: null
        }
      },
      agencies: [],
      updated: false,
      loading: false,
    }),

    components: {
      TopBar: () => import('./AppBar'),
      PageView: () => import('./View'),
      BrandCard: () => import('@/components/BrandCard'),
      UsersControl: () => import('@/components/UsersControl'),
      UserProfile: () => import('@/components/UserProfile'),
      Toast: () => import('@/components/Toast'),
    },

    computed: {
      user: sync('user/data'),
      brand: sync('app/views@brand.selected'),
      brands: sync('clients/items'),
      updatedAt: sync('clients/updatedAt'),
      geofences: sync('app/views@map.cities'),
      toast: sync('app/toast'),

      brandData () {
        const brand = this.views.brand.selected;
        let data = null;
        if (!_.isNil(brand)) {
          if (brand=='new') {
            data = this.views.brand.title;
          }else{
            data = _.clone(this.brands[brand]);
            if (!_.has(data, 'category')||data.category===undefined) data.category = null;
            const users = _.has(data, 'users') ? _.groupBy(data.users, 'advertiser') : [];
            const contracts = _.has(data, 'contracts') ? _.groupBy(data.contracts, 'advertiser') : {};
            if (_.has(data, 'advertisers')&&_.size(data.advertisers)>0) {
              data.advertisers = this.admin ? data.advertisers : _.pickBy(data.advertisers, advertiser => {
                  return _.indexOf(_.map(this.user.advertisers, 'id'), advertiser.id)>=0||(this.user.agency!=null&&advertiser.agency!=null&&advertiser.agency.id==this.user.agency)
              })
              data.advertisers = _.mapValues(data.advertisers, advertiser => {
                return {
                  users: _.has(users, advertiser.id) ? users[advertiser.id] : [],
                  contracts: _.has(contracts, advertiser.id) ? _.keyBy(contracts[advertiser.id], 'id') : {},
                  ...advertiser
                }
              })
            }
          }
        }
        return data;
      },

      multipleAdvertisers () {
        return _.size(this.brandAdvertisers)>1;
      },

      brandAdvertisers () {
        return _.has(this.brandData, 'advertisers') ? _.map(this.brandData.advertisers, a => {
          return { id: a.id, title: this.brandData.title + (a.agency==null ? '' : ' - '+a.agency.name) }
        }) : [];
      },

      advertiserUsers () {
        const brand = this.views.brand.selected;
        const advertiser = this.views.brand.people.advertiser;
        return _.isNil(brand)||brand=='new'||_.isNil(advertiser)||!_.has(this.brandData, 'advertisers')||!_.has(this.brandData.advertisers, advertiser) ? [] : _.clone(this.brandData.advertisers[advertiser].users);
      },
      availableUsers () {
        let brands = [this.views.brand.selected];
        const agency = this.user.agency;
        if (!_.isNil(agency)) brands.push(...this.user.brands);
        return _.reduce(brands, (users, brand) => {
          if (_.has(this.brands, brand)&&_.has(this.brands[brand], 'users')) users.push(..._.clone(this.brands[brand].users))
          return users;
        }, [])
      },

      advertiserProfiles () {
        const brand = this.views.brand.selected;
        const advertiser = this.views.brand.people.advertiser;
        let profiles = _.isNil(brand) ? {} : { 7: { id: 7, title: this.brandData.title }}
        if (advertiser!=null&&_.has(this.brandData, 'advertisers')&&_.has(this.brandData.advertisers, advertiser)&&this.brandData.advertisers[advertiser].agency!=null) {
          profiles[6] = {
            id: 6,
            title: this.brandData.advertisers[advertiser].agency.name
          }
        }
        return profiles;
      },

      disablePeople () {
        const users = this.views.brand.people.users;
        return users==null||_.isEqual(users, this.advertiserUsers)||_.some(this.views.brand.people.users, ['error', true]);
      },

      cities () {
        return _.map(this.geofences, g => {
          const { id, title } = g;
          return {
            id,
            title
          }
        })
      },

      ghost () {
        const user = this.user;
        return _.has(user, 'ghost') && !_.isNil(user.ghost);
      }
    },

    watch: {
      'user.ghost' (now, then) {
        if (then!=now) {
          console.log('ghost redirect');
          let brand = this.brand;
          brand = _.indexOf(this.user.brands, brand.toString())>=0 ? brand : null;

          setTimeout(($) => {
            // $.$router.push('/');
            $.toggleAccess(false);
            if (_.size(this.user.brands)>_.size(this.brands)) $.loadBrands();
          }, 500, this);
        }
      }
    },

    methods: {
      ...services,
      getBrand,
      getBrands,
      createBrand,
      updateBrand,
      getAgencies,
      getUser,
      updateUser,
      getGhostAccess,
      getGeofences,

      addBrand (title) {
        console.log('createBrand', title);
        this.views.brand.title = _.isNil(title) ? '' : title;
        this.views.brand.selected = 'new';
        this.toggleBrand(true);
      },
      manageBrand (id) {
        console.log('manageBrand', id);
        this.views.brand.selected = id;
        this.views.brand.people.advertiser = id;
        this.loadBrand(id);
        this.toggleBrand(true);
      },
      saveBrand (data, callback) {
        this.views.brand.loading = true;
        this.views.brand.updated = false;
        if (data.id == 'new') {
          // createBrand
          this.createBrand(
            this.user.auth.token,
            data,
            (data) => {
              this.$set(this.brands, data.id, data);
              this.toggleBrand(false);
              this.views.brand.updated = true;
              this.$nextTick(() => {
                if (!_.isNil(callback)) callback()
                this.$router.push({
                  name: 'Campanhas',
                  params: { brand: data.id }
                }).catch((error) => {
                  console.warn(error)
                })
              })
            },
            (error) => { 
              const title = data.brand_name;
              const msg = _.has(error.response, 'status') && error.response.status == 500 ? 
                'Ocorreu um erro ao criar a marca ' + title + '. Tente novamente, por favor.' : 
                _.has(error.response.data, 'message') ? _.join(error.response.data.message, ', ') : 'Ocorreu um erro inesperado. Tente novamente, por favor.';

              this.toggleToast(
                true,
                msg,
                15000,
                true
              );
            },
            () => { 
              this.views.brand.loading = false;
            }
          )

        }else{
          // updateBrand
          const id = data.id;
          this.updateBrand(
            this.user.auth.token,
            id,
            data,
            (data) => {
              const msg = _.has(data, 'alert') ? data.alert : 'Dados salvos com sucesso!';
              data = _.omit(data, ['alert']);
              this.brands[id] = Object.assign(this.brands[id], data);
              this.views.brand.updated = true;

              if (!_.isNil(callback)) callback(data)

              this.toggleToast(
                true,
                msg,
                15000,
                false
              );
            },
            (error) => { 
              const title = this.brandData.title;
              const msg = _.has(error.response, 'status') && error.response.status == 500 ? 
                'Ocorreu um erro ao salvar a marca ' + title + '. Tente novamente, por favor.' : 
                _.has(error.response.data, 'message') ? _.join(error.response.data.message, ', ') : 'Ocorreu um erro inesperado. Tente novamente, por favor.';

              this.toggleToast(
                true,
                msg,
                15000,
                true
              );
            },
            () => { 
              this.views.brand.loading = false;
            }
          )
        }
      },
      loadBrand (id, callback) {
        if (this.views.brand.loading) return;
        console.log('getBrand', id);
        this.views.brand.selected = id;
        this.views.brand.people.advertiser = null;
        
        if (_.has(this.brands, id)) {
          this.loading = true;
          this.views.brand.loading = true;
          this.getBrand(
            this.user.auth.token,
            id,
            (data) => {
              data.pois = _.map(data.pois, poi => {
                return {
                  id: poi.custom_id,
                  title: poi.name,
                  type: poi.type_code,
                  use: poi.use_code,
                  position: { lat: poi.lat, lng: poi.log },
                  radius: _.has(poi, 'radius') ? poi.radius : null,
                  url: poi.url,
                  local_id: poi.city_id,
                  city: _.has(poi, 'city') ? poi.city : {
                    id: poi.city_id,
                    title: 'Rio de Janeiro'
                  }
                }
              }),
              this.brands = Object.assign(this.brands, { [id]: data });
              this.views.brand.updated = true;
              if (!_.isNil(callback)) callback(data)
            },
            (error) => { 
              console.log(error);
            },
            () => { 
              this.loading = false;
              this.views.brand.loading = false;
            }
          )

        }
      },
      loadBrands () {
        this.loading = true;
        this.updated = false;
        this.getBrands(
          this.user.auth.token,
          (data) => {
            let del = _.map(this.brands, b => b.id);
            _.each(data, brand => {
              if (!_.has(this.brands, brand.id)) {
                this.$set(this.brands, brand.id, brand);
              }
              del = _.without(del, brand.id);
            });
            _.each(del, c => this.$delete(this.brands, c));
            this.updatedAt = moment().valueOf();
            this.updated = true;
          },
          (error) => { 
            this.toggleToast(
              true,
              'Aguardando resposta da nuvem...',
              7000,
              false
            );
            setTimeout(this.loadBrands, 5000);
          },
          () => { 
            this.loading = false;
          }
        )
      },
      cacheLogo (data) {
        console.log('cacheLogo', data.brand);
        this.$set(this.brands, data.brand, data.logo);
      },
      toggleBrand (b) {
        this.views.brand.toggle = b;
      },

      managePeople (id) {
        console.log('managePeople', id);
        this.views.brand.selected = id;
        this.$nextTick(() => {
          if (!_.has(this.brands[id], 'users')||_.isEmpty(this.brands[id].users)) {
            this.loadBrand(id, () => {
              this.views.brand.people.advertiser = this.suggestAdvertiser(id);
              this.togglePeople(true);
            });
          }else{
            this.views.brand.people.advertiser = this.suggestAdvertiser(id);
            this.togglePeople(true);
          }
        })
      },
      togglePeople (b) {
        this.views.brand.people.toggle = b;
        if (b) {
          this.views.brand.people.users = _.clone(this.advertiserUsers);
        }else{
          this.views.brand.people.users = [];
        }
      },
      updatePeople (users) {
        console.log(users);
        this.views.brand.people.users = users;
      },
      savePeople () {
        this.$nextTick(() => {
          let advertisers = _.clone(this.brandData.advertisers);
          const advertiser_id = this.views.brand.people.advertiser;
          advertisers = _.values(_.merge({}, advertisers, {
            [advertiser_id]: {
              advertiser_id,
              users: this.views.brand.people.users,
              agency: advertisers[advertiser_id].agency,
              contracts: null
            }
          }));
          const data = {
            id: this.views.brand.selected,
            advertisers: JSON.stringify(advertisers),
          };
          this.saveBrand(data, () => {
            this.$nextTick(() => {
              this.togglePeople(true);
            })
          });
        })
      },

      suggestAdvertiser (brand_id) {
        const brand = _.has(this.brands, brand_id) ? this.brands[brand_id] : {};
        return _.has(brand, 'advertisers') && _.size(brand.advertisers)>0 ? 
            _.size(brand.contracts)>0 ? _.first(_.orderBy(brand.contracts, ['period.start'], ['desc'])).advertiser : 
            _.last(_.keys(brand.advertisers)) : 
            null
      },

      toggleUser (b) {
        this.views.user.toggle = b;
        const route = this.$route;
        if (!b&&_.has(route.query, 'profile')) this.$router.push(this.$route.path);
      },
      manageUser (b) {
        b = _.isNil(b) ? true : b;
        if (this.views.user.toggle==b) return;
        console.log('manageUser', b);
        this.views.user.loading = true;
        this.views.user.updated = false;
        this.getUser(
          this.user.auth.token,
          (data) => {
            this.user = _.assign(this.user, data);
            this.views.user.updatedAt = moment().valueOf();
            this.views.user.updated = true;
            this.toggleUser(true);
          },
          (error) => { 
            console.log(error);
          },
          () => { 
            this.views.user.loading = false;
          }
        )
      },
      saveUser (data) {
        this.views.user.loading = true;
        this.views.user.updated = false;
        const id = data.id;
        this.updateUser(
          this.user.auth.token,
          id,
          data,
          (data) => {
            this.user = Object.assign(this.user, data);
            this.views.user.updatedAt = moment().valueOf();
            this.views.user.updated = true;
            this.toggleToast(
              true,
              'Dados salvos com sucesso!',
              15000,
              false
            );
          },
          (error) => { 
            const msg = _.has(error.response, 'status') && error.response.status == 500 ? 
              'Ocorreu um erro ao salvar seus dados. Tente novamente, por favor.' : 
              _.has(error.response.data, 'message') ? _.isArray(error.response.data.message) ? _.join(error.response.data.message, ', ') : error.response.data.message : 'Ocorreu um erro inesperado. Tente novamente, por favor.';

            this.toggleToast(
              true,
              msg,
              15000,
              true
            );
          },
          () => { 
            this.views.user.loading = false;
          }
        )
      },

      toggleAccess (b, id) {
        this.views.access.toggle = b;
        if (b) {
          this.views.brand.selected = id;
          if (!_.has(this.brands[id], 'users')||_.isEmpty(this.brands[id].users)) {
            this.loadBrand(id);
          }
        }else{
          this.views.access.selected = null;
        }
      },

      endAccess () {
        this.updatedAt = null;
        this.updated = false;
        const ghost = JSON.parse(_.clone(this.user.ghost));
        this.user = Object.assign({}, ghost);
      },

      startAccess () {
        this.views.access.loading = true;
        const selected = this.views.access.selected;
        if (_.isNil(selected)||!_.has(selected, 'id')) return;
        this.getGhostAccess(
          this.user.auth.token,
          selected.id,
          (data) => {
            const brands = _.mapValues(_.keyBy(data.marcas, 'brand_id'), (brand) => {
              return {
                id: brand.brand_id,
                title: brand.name,
                logotype: null,
                agencies: [],
                users: []
              }
            });
            this.brands = Object.assign({}, brands);
            if (_.size(brands)>0) {
              const ghost = JSON.stringify(this.user)
              const user = {
                ghost,
                username: selected.email,
                notification: data.notification,
                auth: {
                  token: data.jwtToken,
                  timestamp: moment().valueOf(),
                },
                roles: _.map(data.perfis, role => role.id_perfil),
                advertisers: _.map(data.perfis, profile => profile.id_anunciante),
                brands: _.keys(brands)
              };
              this.user = Object.assign({}, user);
              localStorage.setItem('user', JSON.stringify(user));
            }else{
              this.toggleToast(
                true,
                'O usuário não tem marcas associadas.',
                5000,
                false,
              );
            }
          },
          error => {
            if (_.has(error.response, 'status') && error.response.status==401) {
              this.toggleToast(
                true,
                'Acesso negado.',
                5000,
                false,
              );
            }else{
              this.handleError(error, 'Ocorreu um erro inesperado. Tente novamente, por favor.', true);
            }
          },
          () => {
            this.views.access.loading = false;
          })
      },

      loadGeofences () {
        this.getGeofences(
          this.user.auth.token,
          (data) => {
            this.geofences = _.reduce(data, (cities, country) => { 
              _.each(country.states, state => { 
                _.each(state.cities, city => { 
                  cities[city.id] = {
                    id: city.id, 
                    title: city.name,
                    url: city.url,
                    zones: _.mapValues(_.keyBy(city.zones, 'id'), zone => { 
                      return {
                        id: zone.id,
                        title: zone.name, 
                        url: zone.url,
                        geofences: _.mapValues(_.keyBy(zone.neighborhoods, 'id'), geofence => {
                          return {
                            id: geofence.id,
                            title: geofence.name,
                            url: geofence.url,
                          }
                        })
                      }
                    }),
                    country: {
                      id: country.id,
                      title: country.name
                    },
                    state: {
                      id: state.id,
                      title: state.name
                    },
                  }
                })
              })
              return cities;
            }, {});
          },
          (error) => { 
            setTimeout(this.loadGeofences, 5000);
          },
          () => { 
            this.loading = false;
          }
        );
      },

      onReset () {
        this.$emit('reset');
      }
    },

    beforeDestroy () {
    },

    mounted () {
      const update = _.isNil(this.updatedAt) || moment().diff(this.updatedAt, 'm') >= 5;
      if (update) this.loadBrands();

      this.loadGeofences();

      if (this.admin||_.indexOf(this.user.roles, 17)>=0) {
        this.getAgencies(
          this.user.auth.token,
          (data) => {
            if (data.length) this.agencies = data;
          }
        );
      }
    }
  }
</script>
