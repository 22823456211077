import _ from 'lodash';
const moment = require('moment');

function list (token, resolve, reject, complete) {
  console.log('brands.list ...');

  this.$api.SSP.get('/brands/', {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  .then(response => {
    console.log('brands.list => ', response);
    let data = _.map(response.data, brand => {
      return {
        id: brand.brand_id,
        title: brand.name,
      }
    });
    resolve(data);
  })
  .catch(error => {
    if(_.has(error.response, 'status') && error.response.status==401){
      this.getout('brands list');
      this.handleError(error, 'Sua sessão expirou...', true);
    }else{
      this.handleError(error);
      if (this.isValid(reject)) reject(error);
    }
  })
  .finally(() => {
    // callback function
    if (this.isValid(complete)) complete();
  });
}

function get (token, brand, resolve, reject, complete) {
  console.log('brands.get ...');

  this.$api.SSP.get('/brands/'+brand, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  .then(response => {
    console.log('brands.get => ', response);
    const brand = response.data;
    const data = {
      id: brand.brand_id,
      title: brand.name,
      category: brand.category,
      logotype: brand.logotype,
      cities: _.map(_.split(brand.licensed_cities, ','), c => parseInt(c)),
      pois: brand.customs,
      advertisers: _.keyBy(_.map(brand.advertisers, advertiser => {
        return {
          id: advertiser.advertiser_id,
          agency: advertiser.agency_id==null ? null : { 
            id: advertiser.agency_id, 
            name: advertiser.agency_name 
          }
        }
      }), 'id'),
      contracts: _.keyBy(_.map(brand.contracts, contract => {
        return {
          advertiser: contract.advertiser_id,
          id: contract.contract_id,
          budget: contract.budget,
          provisioned: contract.provisioned_budget,
          impressions: contract.impressions,
          price: contract.price,
          period: {
            start: moment.utc(contract.dt_begin).local().format('YYYY-MM-DD'),
            end: moment.utc(contract.dt_end).local().format('YYYY-MM-DD'),
          },
        }
      }), 'id'),
      users: _.map(brand.users, user => {
        return {
          advertiser: user.advertiser_id,
          name: user.name==null ? user.email : user.name,
          email: user.email,
          id: user.user_id,
          profile: user.profile,
        }
      })
    }
    resolve(data);
  })
  .catch(error => {
    if(_.has(error.response, 'status') && error.response.status==401){
      // this.getout('brands.get');
      this.handleError(error, 'Sua sessão expirou...', true);
    }else{
      this.handleError(error);
      if (this.isValid(reject)) reject(error);
    }
  })
  .finally(() => {
    // callback function
    if (this.isValid(complete)) complete();
  });
}

function create (token, data, resolve, reject, complete) {
  console.log('brands.create ...', data);

  this.$api.SSP.post(`/brands/`, new URLSearchParams(data), {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
  .then(response => {
    console.log('brands.create => ', response);
    const brand = response.data;
    const data = {
      id: brand.brand_id,
      title: brand.name,
      category: brand.category,
      logotype: brand.logotype,
      cities: _.map(_.split(brand.licensed_cities, ','), c => parseInt(c)),
      advertisers: _.keyBy(_.map(brand.advertisers, advertiser => {
        return {
          id: advertiser.advertiser_id,
          agency: advertiser.agency_id==null ? null : { 
            id: advertiser.agency_id, 
            name: advertiser.agency_name 
          }
        }
      }), 'id'),
      contracts: _.keyBy(_.map(brand.contracts, contract => {
        return {
          advertiser: contract.advertiser_id,
          id: contract.contract_id,
          budget: contract.budget,
          provisioned: contract.provisioned_budget,
          impressions: contract.impressions,
          price: contract.price,
          period: {
            start: moment.utc(contract.dt_begin).local().format('YYYY-MM-DD'),
            end: moment.utc(contract.dt_end).local().format('YYYY-MM-DD'),
          },
        }
      }), 'id'),
      users: _.map(brand.users, user => {
        return {
          advertiser: user.advertiser_id,
          name: user.name==null ? user.email : user.name,
          email: user.email,
          id: user.user_id,
          profile: user.profile,
        }
      })
    }
    resolve(data);
  })
  .catch(error => {
    if(_.has(error.response, 'status') && (error.response.status==401)){
      this.getout();
      this.handleError(error, 'Sua sessão expirou...', true);
    }else{
      this.handleError(error);
      if (this.isValid(reject)) reject(error);
    }
  })
  .finally(() => {
    if (this.isValid(complete)) complete();
  });
}

function update (token, brand, data, resolve, reject, complete) {
  console.log('brands.update ...', data);

  this.$api.SSP.put(`/brands/${brand}`, new URLSearchParams(data), {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
  .then(response => {
    console.log('brands.update => ', response);
    const brand = response.data;
    const data = {
      id: brand.brand_id,
      title: brand.name,
      category: brand.category,
      logotype: brand.logotype,
      cities: _.map(_.split(brand.licensed_cities, ','), c => parseInt(c)),
      advertisers: _.keyBy(_.map(brand.advertisers, advertiser => {
        return {
          id: advertiser.advertiser_id,
          agency: advertiser.agency_id==null ? null : { 
            id: advertiser.agency_id, 
            name: advertiser.agency_name 
          }
        }
      }), 'id'),
      contracts: _.keyBy(_.map(brand.contracts, contract => {
        return {
          advertiser: contract.advertiser_id,
          id: contract.contract_id,
          budget: contract.budget,
          provisioned: contract.provisioned_budget,
          impressions: contract.impressions,
          price: contract.price,
          period: {
            start: moment.utc(contract.dt_begin).local().format('YYYY-MM-DD'),
            end: moment.utc(contract.dt_end).local().format('YYYY-MM-DD'),
          },
        }
      }), 'id'),
      users: _.map(brand.users, user => {
        return {
          advertiser: user.advertiser_id,
          name: user.name==null ? user.email : user.name,
          email: user.email,
          id: user.user_id,
          profile: user.profile,
        }
      })
    }
    resolve(data);
  })
  .catch(error => {
    if(_.has(error.response, 'status') && (error.response.status==401)){
      this.getout();
      this.handleError(error, 'Sua sessão expirou...', true);
    }else{
      this.handleError(error);
      if (this.isValid(reject)) reject(error);
    }
  })
  .finally(() => {
    if (this.isValid(complete)) complete();
  });
}

function agencies (token, resolve, reject, complete) {
  console.log('agencies.list ...');

  this.$api.SSP.get('/agencies/', {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  .then(response => {
    console.log('agencies.list => ', response);
    let data = _.concat(_.map(response.data, agency => {
      return {
        id: agency.id,
        name: agency.name,
      }
    }), { name: '–', id: null });
    resolve(data);
  })
  .catch(error => {
    if(_.has(error.response, 'status') && error.response.status==401){
      this.handleError(error, 'Sua sessão expirou...', true);
    }else{
      this.handleError(error);
      if (this.isValid(reject)) reject(error);
    }
  })
  .finally(() => {
    // callback function
    if (this.isValid(complete)) complete();
  });
}

export {
  list,
  get,
  create,
  update,
  agencies
}
